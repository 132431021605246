<template>
  <div class="height1">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item label="处方类型">
                <el-select v-model="searchForm.type" placeholder="请选择" size="small" style="margin-right: 10px; width: 120px" @change="Search">
                  <el-option v-for="item in categoryData" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="输入处方名称..." @keyup.enter.native="Search" clearable ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
                <el-button type="primary" size="small" @click="AddPrescription(1)" plain>新增方剂处方</el-button>
                <el-button type="primary" size="small" @click="AddPrescription(2)" plain>新增成药处方</el-button>
                <el-button type="primary" size="small" @click="AddPrescription(3)" plain>新增输液处方</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" height="calc(100% - 80px)">
          <el-table-column type="index" label="编号" width="50"></el-table-column>
          <el-table-column prop="prescriptionName" label="处方名称"> </el-table-column>
          <el-table-column prop="prescriptionType" label="类型" align="center" sortable>
            <template slot-scope="scope">
              <p v-if="scope.row.type==0">方剂处方</p>
              <p v-if="scope.row.type==1">成药处方</p>
              <p v-if="scope.row.type==2">输液处方</p>
            </template>
           </el-table-column>
          <el-table-column prop="addTime" label="添加时间" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.addTime | timefilters }}</span>
            </template>
           </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <span class="info optionBtn" @click="EditBtn(scope.row)">编辑</span>
              <span class="danger optionBtn" @click="RemoveBtn(scope.row)">删除</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import bread from '../../../components/common/breadcrumb'
import { Outpatient } from "@/components/DrugDomain/Outpatient"
export default {
  components: {
    bread,
  },
  data() {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    return {
      OutpatientDomain: outpatient,
      setDialog: false,
      tableData: [],
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      DialogTitle: '添加银行卡',
      form:{},
      searchForm: {
        type: '-1',
        keyWord: '',
      },
      categoryData: [
        {
          value: '-1',
          label: '全部',
        },
        {
          value: '0',
          label: '方剂处方',
        },
        {
          value: '1',
          label: '成药处方',
        },
        {
          value: '2',
          label: '输液处方',
        },
      ],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    Search(){
      this.pageIndex=1
      this.getList() 
    },
    changePage (pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
    getList () {
      var _this = this
      _this.tableData=[]
      _this.OutpatientDomain.PrecriptionListOrg(this.searchForm.type,this.searchForm.keyWord, this.pageIndex,
        function (data) {
          _this.tableData = data.data.results
          _this.pageIndex = data.data.pageIndex
          _this.pageSize = data.data.pageSize
          _this.dataTotal = data.data.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
    AddPrescription(num) {
      let path = ''
      if (num == 1) {
        path = './AddPillDrugPrescription'
      } else if (num == 2) {
        path = './AddProductDrugPrescription'
      } else if (num == 3) {
        path = './AddInfusionDrugPrescription'
      }
      this.$router.push(path)
    },
    EditBtn(e) {
      let path = ''
      if (e.type == 0) {
        path = './AddPillDrugPrescription'
      } else if (e.type == 1) {
        path = './AddProductDrugPrescription'
      } else if (e.type == 2) {
        path = './AddInfusionDrugPrescription'
      }
      this.$router.push({
        path:path,
        query:{
          id:e.id
        }
      })
    },
    RemoveBtn(e) {
      var _this = this
      _this
        .$confirm('确定删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
        .then(() => {
          _this.OutpatientDomain.RemovePrecription(
            e.id,
            function (data) {
              _this.$message({
                type: 'success',
                message: '删除成功!',
              })
              _this.getList()
            },
            function (err) {
              console.log(err)
            }
          )
        })
        .catch(() => {})
    },
    CloseBtn(formName) {
      this.setDialog = false
      this.$refs[formName].resetFields()
    },
  }
}
</script>

<style scoped>
.dialog-form {
  width: 85%;
}
</style>
